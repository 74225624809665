import React from 'react';
import { HttpMethod } from '../model/sdkModel';

interface IProps {
  name: HttpMethod;
  onClick?: () => void;
}


const getBackgroundColorFromMethodName = (name: HttpMethod): string => {

  switch (name) {
    case 'get':
      return 'var(--userful-bg-doc-get)';
    case 'delete':
      return 'var(--userful-bg-doc-delete)';
    case 'post':
      return 'var(--userful-bg-doc-post)';
    case 'put':
      return 'var(--userful-bg-doc-put)';
  }
  return 'var(--userful-gray-dark)';
}

export default function ApiMethodIcon({ name, onClick }: IProps) {

  return (
    <div
      className={`methodIcon ${onClick ? 'interactable' : ''}`}
      style={{ background: getBackgroundColorFromMethodName(name) }}
      onClick={onClick}>
      {name}
    </div>
  );

}
