import { configureStore } from '@reduxjs/toolkit'
import React from 'react';
import {
  TypedUseSelectorHook,
  createDispatchHook,
  createSelectorHook,
} from 'react-redux'
import apiSlice from './apiSlice'

const apiStore = configureStore({
  reducer: {
    apiStore: apiSlice,
  },
})
export default apiStore;

export const APIContext = React.createContext(null);

export type RootState = ReturnType<typeof apiStore.getState>;
export type AppDispatch = typeof apiStore.dispatch;
export const useAPISelector: TypedUseSelectorHook<RootState> = createSelectorHook(APIContext);
export const useAPIDispatch = createDispatchHook<AppDispatch>(APIContext)
