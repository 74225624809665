import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { convertSchemaRefs } from '../../model/sdkModel';
import { useAPISelector } from '../../store/apiStore';

interface IProps {
  mediaTypeDef: any;
  item: string;
}


export default function ApiMethodContentValue({ mediaTypeDef, item }: IProps) {

  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);
  const [open, setOpen] = useState<boolean>(item === 'example');
  console.warn(mediaTypeDef[item]);
  return <div className="schemaContainer">
    <div className='lable interactable' onClick={() => setOpen(!open)}>{item} <FontAwesomeIcon icon={open? faCaretRight : faCaretDown}/></div>
    <Collapse in={open}>
      <pre className="codeContainer">{JSON.stringify(convertSchemaRefs(mediaTypeDef[item], apiDoc.components), null, 2)}</pre>
    </Collapse>
  </div>
}
