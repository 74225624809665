import React, { useEffect, useState } from 'react';
import { ContentDef, convertSchemaRefs } from '../../model/sdkModel';
import { useAPISelector } from '../../store/apiStore';
import ApiMethodContentValue from './ApiMethodContentValue';

interface IProps {
  data: ContentDef;
}


export default function ApiMethodContent({ data }: IProps) {

  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);

  return (
    <div>
      {Object.keys(data).map(mediaType => {
        const mediaTypeDef = data[mediaType];
        return <div className="singleMediaType" key={mediaType}>
          <div className='typeHeading'>
            <div className='lable'>Media type:</div>
            <div>{mediaType}</div>
          </div>
          <div className="schemaDiv">
            {Object.keys(mediaTypeDef).map(item => (
              <ApiMethodContentValue mediaTypeDef={mediaTypeDef} item={item} key={mediaType + item}/>
            ))}
          </div>
        </div>
      })}
    </div>
  );
}
