import React, { useEffect, useState } from 'react';
import { HttpMethod, MethodDef } from '../model/sdkModel';
import { apiSliceActions } from '../store/apiSlice';
import { useAPIDispatch } from '../store/apiStore';
import ApiMethodIcon from './ApiMethodIcon';

interface IProps {
  method: HttpMethod;
  methodDef: MethodDef;
  path: string;
}


export default function ApiMethod({ method, methodDef, path }: IProps) {

  const dispatch = useAPIDispatch();

  const handleClick = () => dispatch(apiSliceActions.setCurrentMethod({
    method,
    methodDef,
    path
  }))

  return (
    <ApiMethodIcon name={method} onClick={handleClick} />
  );

}
