export interface SDKJson {
    openapi: string;
    info: SDKInfo;
    tags: SDKTag[];
    paths: SDKPaths;
    components: SDKComponents;
}

export interface SDKInfo {
    title: string;
    description: string;
    contact: {
        name: string;
        url: string;
        email: string;
    },
    license: {
        name: string; // copyright
        url: string;
    },
    version: string; // version
}

export interface SDKTag {
    name: string;
    description?: string;
}

export interface SDKPaths {
    [path: string]: PathDef;
}

export type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'connect' | 'options';

export type PathDef = {
    [key in HttpMethod]: MethodDef;
}

export interface MethodDef {
    tags: string[]; // match SDKTag names
    summary: string;
    requestBody: RequestBodyDef;
    responses: ResponsesDef;
    security: SecurityDef[];
}

export interface RequestBodyDef {
    "content": ContentDef;
}

export interface ContentDef {
    "application/json": {
        "schema": {
            "$ref": string; // reference back to components e.g. #/components/schemas/SdkReplyVoid
        },
        "example"?: any;
    }
}

export interface ResponsesDef {
    [reponseCode: string]: ResponseDescription;
}

export interface ResponseDescription {
    "description": string;
    "content"?: ContentDef;
}

export interface SecurityDef {
    SecurityScheme: any[]; // to-do: missing options
}

export interface SDKComponents {
    "schemas": SchemaDefs;
    "securitySchemes": SecuritySchemaDefs;
}

export interface SchemaDefs {
    [name: string]: TypeDef;
}

export interface TypeDef {
    "$ref"?: string; // reference back to components e.g. #/components/schemas/SdkReplyVoid - check first
    type?: 'object' | 'string' | 'number' | 'boolean' | 'integer';
    format?: 'double' | 'int32'; // for number or integer type
    enum?: string[]; // for string type;
    properties?: {
        [key: string]: TypeDef;
    }
}

export interface SecuritySchemaDefs {
    [name: string]: SecuritySchema;
}

export interface SecuritySchema {
    type: string;
    description: string;
    [key: string]: string;
}

export const ROOT_PATH = "/sdk"
export const getRootPath = (version: string) => `${ROOT_PATH}/${version}`;

const objectConstructor = ({}).constructor;
export const convertSchemaRefs = (def: object, components: SDKComponents): object => {
    if (!def) {
        return null;
    }
    if (def.constructor !== objectConstructor) {
        // not an object
        return def;
    }
    let sanitized = {};
    for (const [key, value] of Object.entries(def)) {
        if (value.constructor === objectConstructor) {
            sanitized = {
                ...sanitized,
                [key]: convertSchemaRefs(value as object, components),
            }
        } else if (key === '$ref') {
            // #/components/schemas/SdkReplyVoid
            const path = value as string;
            const parts = path.split('/');
            const foundComponent = components[parts[2]] && components[parts[2]][parts[3]];
            if (foundComponent) {
                return { 
                    title: parts[3],
                    ...convertSchemaRefs(foundComponent, components) 
                };
            }
        } else {
            sanitized = {
                ...sanitized,
                [key]: value,
            }
        }
    }
    if (sanitized['type'] === "object" && !sanitized['properties']) {
        sanitized['type'] = ["object", "string"];
    }
    return sanitized;
}