import React, { useEffect, useState } from 'react';
import { useAPISelector } from '../store/apiStore';
import ApiCatagory from './ApiCatagory';


export default function ApiList() {

  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);
  const tags = apiDoc.tags.map(item => item.name);
  
  return (
    <div className='apiListContainer'>
      {tags.map(tag => <ApiCatagory tagName={tag} key={`catagory-${tag}`} />)}
    </div>
  );

}
