import React, { useCallback, useEffect, useRef, useState } from 'react';
import './CodeBlock.scss';

interface IProps {
  children: string;
}

export default function CodeBlock({ children }: IProps) {
  const codeRef = useRef<HTMLDivElement>(null);

  const [lineNumbers, setLineNumbers] = useState<number>(0);

  useEffect(() => {
    if (codeRef.current) {
      const codeEditorCanvas = codeRef.current;
      const lineHeight = 30; // Adjust this value to set the line height
      const lines = codeEditorCanvas.offsetHeight / lineHeight; // Calculate the number of lines based on canvas height
      setLineNumbers(Math.ceil(lines));
    }
  }, [codeRef])

  return (
    <div className='codeBlock'>
      <div className='lineNumbers'>{
        lineNumbers && [...Array(lineNumbers).keys()].map(i => <div key={`code-line-${i}`}>{i+1}</div>)
      }</div>
      <div ref={codeRef}>{children}</div>
    </div>
  );

}
