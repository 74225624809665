import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { getRootPath, HttpMethod, PathDef, SDKJson } from '../model/sdkModel';
import ApiMethod from './ApiMethod';

interface IProps {
  data: {
    path: string;
    pathDef: PathDef;
  }
}


export default function ApiPath({ data }: IProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className='apiPath'>
        <div className='pathContainer'>
          <div className="path interactable" onClick={() => setOpen(!open)}>{data.path}</div>
          <div className='methodHorizonalList'>
            {Object.keys(data.pathDef).map(method => (
              <ApiMethod method={method as HttpMethod} methodDef={data.pathDef[method]} path={data.path} key={`${data.path}-${method}`} />
            ))}
          </div>
        </div>
        <Collapse in={open}>
          <div className="methodVerticalList">
            {Object.keys(data.pathDef).map(method => (
              <div className="methodWithDescription">
                <ApiMethod method={method as HttpMethod} methodDef={data.pathDef[method]} path={data.path} key={`${data.path}-${method}`} />
                {data.pathDef[method].summary}
              </div>
            ))}
          </div>
        </Collapse>
      </div>

    </>
  );

}
