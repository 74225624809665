import { useAPISelector } from '../store/apiStore';
import ApiList from './ApiList';
import ApiMethodDialog from './dialog/ApiMethodDialog';
import './ApiDoc.scss';
import ApiNav from './ApiNav';


export default function ApiDoc() {
  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);
  if (!apiDoc) {
    return <div>Loading ...................................</div>
  }


  return (
    <>
      {/* <ApiNav /> */}
      <div className='apiTopContainer'>
        <div className='apiContainer' >
          <div className="apiDiv">
            API User Guide
            <span className='version'>version {apiDoc.info.version}</span>
          </div>
          <div className='apiBody'>
            <ApiList />
          </div>
          <ApiMethodDialog />
        </div>
      </div>
    </>
  );

}
