import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { getRootPath, PathDef, SDKJson } from '../model/sdkModel';
import { useAPISelector } from '../store/apiStore';
import ApiPath from './ApiPath';

interface IProps {
  tagName: string;
}


export default function ApiCatagory({ tagName }: IProps) {
  const [open, setOpen] = useState(true);
  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);
  const rootPath = getRootPath(apiDoc.info.version);
  const filteredPath: {
    path: string;
    pathDef: PathDef;
  }[] = Object.entries(apiDoc.paths)
    .filter(([key, value]) => Object.values(value).findIndex(method => method.tags.includes(tagName)) >= 0)
    .map(([key, value]) => ({
      path: key.replace(rootPath, ''),
      pathDef: value,
    }));

  if (filteredPath.length < 1) {
    return null;
  }
  return (
    <section id={tagName}>
      <div className='apiCatagoryContainer'>
        <div className="catagoryTitle  interactable" onClick={() => setOpen(!open)}>{tagName}</div>
        <Collapse in={open}>
          <div className='catagoryList'>
            {filteredPath.map(item => <ApiPath data={item} key={item.path} />)}
          </div>
        </Collapse>
      </div>
    </section>
  );

}
