import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HttpMethod, MethodDef, SDKJson } from "../model/sdkModel";

interface IState {
     currentCatagory: string;
     currentMethod: {
          methodDef: MethodDef;
          method: HttpMethod;
          path: string;
     };
     apiDoc: SDKJson;
}

const initialState: IState = {
     currentCatagory: null,
     currentMethod: null,
     apiDoc: null,
};

export const apiSlice = createSlice({
     name: "apiSlice",
     initialState,
     reducers: {
          setAPIDoc: (state, action: PayloadAction<SDKJson>) => {
               state.apiDoc = action.payload;
          },
          setCurrentCatagory: (state, action: PayloadAction<string>) => {
               state.currentCatagory = action.payload;
          },
          setCurrentMethod: (state, action: PayloadAction<{
               methodDef: MethodDef;
               method: HttpMethod;
               path: string;
          }>) => {
               state.currentMethod = action.payload;
          },
     },
});

export const apiSliceActions = apiSlice.actions;

export default apiSlice.reducer;
