import React, { useEffect, useState } from 'react';
import { SDKJson } from './model/sdkModel';
import { apiSliceActions } from './store/apiSlice';
import { useAPIDispatch } from './store/apiStore';
import './App.scss';
import { SideNav, UserfulPageLayout } from 'userful-chronos-common-ui/dist';
import GetStarted from './components/GetStarted';
import { faCodeCommit, faCodePullRequest, faFile, faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import ApiDoc from './components/ApiDoc';
import Examples from './components/Examples';
import { useAPISelector } from './store/apiStore';

const DATA_PATH = 'data/chronos_sdk_openapi.json';

const CONTENTS = {
  getStarted: "Get Started",
  apiDoc: "API Document",
  examples: "Examples"
}

const JS_SAMPLE = 'data/sdkDoc.js';
const PY_SAMPLE = 'data/sdkDoc.py';

export default function App() {

  const dispatch = useAPIDispatch();

  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);
  const tags = apiDoc ? apiDoc.tags.map(item => item.name).filter(tag => Object.entries(apiDoc.paths)
    .filter(([key, value]) => Object.values(value).findIndex(method => method.tags.includes(tag)) >= 0).length > 0) : null;

  const [showContent, setShowContent] = useState(CONTENTS.getStarted);
  const [overrideShowContent, setOverrideShowContent] = useState(null);

  const handleChangeShowContent = (content: string) => {
    if (tags?.includes(content)) {
      setShowContent(CONTENTS.apiDoc);
      window.location.hash = `#${content}`
      setOverrideShowContent(content);
    }
    else {
      setShowContent(content);
      setOverrideShowContent(null);
    }
  }



  useEffect(() => {
    fetch(DATA_PATH, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(json => dispatch(apiSliceActions.setAPIDoc(json as SDKJson)));
  }, [])
  const menuItems = [
    {
      title: CONTENTS.getStarted,
      icon: {
        iconName: faPersonWalking,
        width: "18px",
      }
    },
    tags !== null
      ?
      {
        subMenuHeaderTitle: CONTENTS.apiDoc,
        icon: {
          iconName: faFile,
          width: "18px",
        },
        subMenu: tags.map(tag => ({
          title: `${tag}`,
        })),
      }

      :
      {
        title: CONTENTS.apiDoc,
        icon: {
          iconName: faFile,
          width: "18px",
        },
      },

    {
      subMenuHeaderTitle: CONTENTS.examples,
      icon: {
        iconName: faCodePullRequest,
        width: "18px",
      },
      subMenu: [{
        title: `Python`,
      },
      {
        title: `Javascript`,
      }]
    },
  ];
  console.warn(showContent);

  return (
    <UserfulPageLayout showNotification={false}>
      <div className='main'>
        <SideNav
          handleChangeShowContent={handleChangeShowContent}
          showContent={showContent}
          menuItems={menuItems}
          overrideShowContent={overrideShowContent}
          noTranslate
        />
        <div className='contentContainer'>
          {showContent === CONTENTS.getStarted && <GetStarted />}
          {showContent === CONTENTS.apiDoc && <ApiDoc />}
          {showContent === "Python" && <Examples path={PY_SAMPLE} name={"Python"}/>}
          {showContent === "Javascript" && <Examples path={JS_SAMPLE} name={"Javascript"}/>}
        </div>
      </div>
    </UserfulPageLayout>
  );

}
