import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/inter/100.css"
import "@fontsource/inter/200.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/800.css"
import "@fontsource/inter/900.css"
import apiStore, { APIContext } from './store/apiStore';
import { Provider } from 'react-redux';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={apiStore} context={APIContext}>
    <App />
  </Provider>
  ,
);

