import React, { useEffect, useState } from 'react';
import './ApiDoc.scss';
import CodeBlock from './code/CodeBlock';

interface IProps {
  path: string;
  name: string;
}


export default function Examples(props: IProps) {
  const [sampleCode, setSampleCode] = useState<string>(null);
  useEffect(() => {
    fetch(props.path)
      .then((res) => res.text())
      .then(text => setSampleCode(text));
  }, [])

  return (
    <>
      <div className='apiTopContainer' style={{ width: '100%' }}>
        <div className='apiContainer'>
          <div className='descriptionContainer'>
            <h3>Example Code</h3>
            <div className='description'>
              Here is a code sample written in {props.name} to query system information.
            </div>
          </div>
          {sampleCode && <CodeBlock>{sampleCode}</CodeBlock>}
        </div>
      </div >
    </>
  );

}
