import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { apiSliceActions } from '../../store/apiSlice';
import { useAPIDispatch, useAPISelector } from '../../store/apiStore';
import ApiMethodIcon from '../ApiMethodIcon';
import ApiMethodContent from './ApiMethodContent';
import "./ApiMethodDialog.scss";


const statusCodeRegex = /\d+/;
const sanitizeCode = (code: string) => {
  const foundResults = code.match(statusCodeRegex);
  return (foundResults && foundResults[0] || code);
};

export default function ApiMethodDialog() {

  const selectedAPIMethod = useAPISelector(state => state.apiStore.currentMethod);
  const dispatch = useAPIDispatch();
  const handleClose = () => dispatch(apiSliceActions.setCurrentMethod(null));

  return (
    <Modal
      show={!!selectedAPIMethod}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
    >
      {selectedAPIMethod &&
        <>
          <div className='modal-header-title' style={{ marginBottom: '0px' }}>
            <Modal.Header>
              <div className='methodDialogHeader'>
                <ApiMethodIcon name={selectedAPIMethod.method} /> {selectedAPIMethod.path}
              </div>
              <div className='modal-close-btn'>
                <FontAwesomeIcon icon={faClose} onClick={handleClose} className="custom-close-btn" />
              </div>
            </Modal.Header>
          </div>
          <hr />
          <Modal.Body>
            <div className='methodDescription'>
              {selectedAPIMethod.methodDef.summary}
            </div>
            <Tabs>
              <Tab title="Request" eventKey={1} >
                {selectedAPIMethod.methodDef.requestBody && selectedAPIMethod.methodDef.requestBody.content ?
                  <div className='requestContainer'>
                    <ApiMethodContent data={selectedAPIMethod.methodDef.requestBody.content} />
                  </div>
                  :
                  <div>Empty Request</div>
                }

              </Tab>

              <Tab title="Response" eventKey={2} >
                {selectedAPIMethod.methodDef.responses ?
                  <div className='responseContainer'>
                    {Object.keys(selectedAPIMethod.methodDef.responses).map(statusCode => {
                      const sanizedCode = sanitizeCode(statusCode);
                      if (sanizedCode === '200' && sanizedCode !== statusCode && !statusCode.includes('SUCCESS')) {
                        return null;
                      }
                      const codeContent = selectedAPIMethod.methodDef.responses[statusCode];
                      return <div className="singleMediaType" key={statusCode}>
                        <div className='lable'>HTTP status code <a target='_blank' href={`https://httpstatus.es/${sanizedCode}`}>{sanizedCode}</a></div>
                        <hr />
                        <div className="description">{codeContent.description}</div>
                        {codeContent.content && <div style={{marginTop: '10px'}}>
                          <ApiMethodContent data={codeContent.content} />
                        </div>}
                      </div>
                    })}
                  </div>
                  :
                  <div>Empty Response</div>
                }

              </Tab>
            </Tabs>
          </Modal.Body>
        </>}
    </Modal>
  );

}
