import React, { useEffect, useState } from 'react';
import { useAPISelector } from '../store/apiStore';
import './ApiDoc.scss';

export default function GetStarted() {
  const apiDoc = useAPISelector(state => state.apiStore.apiDoc);

  if (!apiDoc) {
    return <div>Loading ...................................</div>
  }

  return (
    <>
      <div className='apiTopContainer' style={{ width: '100%' }}>
        <div className='apiContainer'>
          <div className="apiDiv">
            Userful Infinity API
            <span className='version'>version {apiDoc.info.version}</span>
          </div>
          <div className='descriptionContainer'>
            <h3>Copyright</h3>
            <div className='description'>{apiDoc.info.license.name}</div>
          </div>

          <div className='descriptionContainer'>
            <h3>Audience</h3>
            <div className='description'>
              The Userful API uses the REST architecture.
              Developers who know programming languages such as Python, JavaScript etc can use this API guide to interact with the Userful platform programmatically.
            </div>
          </div>

          <div className='descriptionContainer'>
            <h3>Authentication</h3>
            <div className='description'>
              Userful API uses tokens for authentication. You need to pass an authentication request to receive the token.
              The token is valid for one month.The authentication request is as follows:
            </div>
            <div className='noteBlock'>
              POST: https://&#60;FQDN&#62;/kc/open/authorizesdk with body
              <br />
              {`{
                  "username": "admin",
                  # Replace with your password
                  "password": "*******"
}`}
            </div>
            <div className='description'>
              For every other API request, you need to pass the access token as a variable in the header of the request.
              For this purpose, create a variable named Authorization and assign it the value of the access token prefixed by "Bearer ".
            </div>
          </div>

          <div className='descriptionContainer'>
            <h3>Requests and Responses</h3>
            <div className='description'>
              The Userful API accepts JSON requests, returns JSON responses, and uses standard HTTP response codes and authentication.
            </div>
          </div>

          <div className='descriptionContainer'>
            <h3>Access the Userful Infinity Platform</h3>
              <div className='description' style={{ marginBottom: "4px" }}>
                You can use the URL below to access the Userful Infinity platform. This URL is created during the initial installation by the admin.
              </div>

              <div className='description'>
                To make API calls, append the API's endpoint path to the following URL.
              </div>
            <div className='noteBlock'>
              https://&#60;FQDN&#62;/sdk/{apiDoc.info.version}
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
